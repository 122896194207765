export const teamPayoutAccount = {
  order: 2,
  name: "Connect a shared payout account to a team",
  subcategory: "Manage teams",
  markdown: `# Connect a shared payout account to a team
  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="userGroup" /%}Team {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}**primary owners**{% /inlineRouterLink %} and {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}**billing members**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  In Tower Hunt, teams make it easy for groups of users to share earnings. Read on to learn how.

  {% callout type="caution" %}
  **Important:** First, make sure you have an active {% inlineRouterLink articleId="manage-payout-accounts" sectionName="Shared payout account" %}shared payout account{% /inlineRouterLink %}.
  {% /callout %}

  ## Connect a shared payout account

  {% tablessContainer %}
1. Open the {% inlineAppIcon iconName="userGroup" %}**My Teams**{% /inlineAppIcon %} section of the **Contacts** tab in the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}.
2. Find or {% inlineRouterLink articleId="create-a-team" %}create a team{% /inlineRouterLink %}.
3. Click the {% inlineAppIcon iconName="verticalDots" /%}**team menu button** to the right of the team name and choose **Manage**.
4. Find your name in the list of team members.
5. Click the {% inlineAppIcon iconName="verticalDots" /%}**member menu button** to the right of your name and choose **Connect payout account**.
6. Select the desired shared payout account from the list. It now appears below your name.
![Team shared payout account screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
{% /tablessContainer %}

## Select a primary payout account

When multiple shared payout accounts are connected to a team, one becomes the *primary* account that team members can {% inlineRouterLink articleId="manage-payout-accounts" sectionName="Set a default payout account" %}select as their default payout account{% /inlineRouterLink %} or {% inlineRouterLink articleId="manage-payout-accounts" sectionName="Datapoint earnings override" %}use as a datapoint earnings override{% /inlineRouterLink %}. When team members choose to send earnings to the team, the primary account will be used.

{% tablessContainer %}
1. Open the {% inlineAppIcon iconName="userGroup" %}**My Teams**{% /inlineAppIcon %} section of the **Contacts** tab in the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}.
2. Click the {% inlineAppIcon iconName="verticalDots" /%}**team menu button** to the right of the desired team name and choose **Manage**.
4. Find the desired shared payout account under a team member's name.
5. Click the {% inlineAppIcon iconName="star" /%}**Star button** to set the shared payout account as the primary payout account for the team.
![Primary team shared payout account screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
{% /tablessContainer %}`,
};
